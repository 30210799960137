import './App.css';
import React from 'react';

export const Footer = () => {
    return (
        <div className='footer'>
            {/* <img src='/background.png' width={300} draggable={false} alt={""} /> */}
            <p style={{fontSize: "medium", margin: "0px 0px 10px 10px", alignSelf: "end", color: "#bbb"}}>{'© RunQuest Inc.'}</p>
        </div>
    );
};
