import './App.css';
import React from 'react';
import { Post } from './Post';

export const Feed = () => {
    return (
        <div className='feed-box'>
            <h3 style={{ margin: 0, marginBottom: "10px" }}>Feed</h3>
            <Post
                name={"Jake Kaplow"}
                username={"@jakekaplow"}
                date={"06/03/2019 10:48am"}
                text={<><p>git init</p></>} />
        </div>
    );
};