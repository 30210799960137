import './App.css';
import { Feed } from './Feed';
import { Footer } from './Footer';
import { Header } from './Header';

export const App = () => {
  return (
    <div className="App">
      <Header />
      <div style={{ display: "flex", flexDirection: "row-reverse", flexWrap: "wrap", paddingLeft: "20px", paddingRight: "20px" }}>
        <div style={{ marginInlineStart: "10px", flex: 2, display: "flex", flexDirection: "column", marginBottom: "10px" }}>
          <h3 style={{ margin: 0, marginBottom: "10px" }}>About</h3>
          <span className='about-text'>
            <p>A Washington corporation organized to engage in any lawful purpose, including:</p>
            <p>
              <ul>
                <li>something cool along the lines of 'run' and 'quest'</li>
              </ul>
            </p>
          </span>
        </div>
        <Feed />
      </div>
      <Footer />
    </div>
  );
}
